import {
  SiAdobephotoshop,
  SiAngularjs,
  SiBootstrap,
  SiCplusplus,
  SiCsharp,
  SiJquery,
  SiKotlin,
  SiMicrosoftsqlserver,
  SiPython,
  SiVisualstudio,
} from "react-icons/si";
import {
  FaHtml5,
  FaJsSquare,
  FaCss3,
  FaSass,
  FaReact,
  FaDatabase,
  FaJava,
  FaLess,
  FaGithub,
  FaGitlab,
} from "react-icons/fa";
import { DiJira } from "react-icons/di";

export const Experience = () => {
  const icons = [
    {
      icon: <FaReact title="ReactJs &amp; React Native" />,
      title: "React",
    },
    {
      icon: <FaHtml5 title="HTML5" />,
      title: "HTML5",
    },
    {
      icon: <FaJsSquare title="Javascript" />,
      title: "JavaScript",
    },
    {
      icon: <FaCss3 title="CSS3" />,
      title: "CSS3",
    },
    {
      icon: <FaSass title="Sass" />,
      title: "SASS",
    },
    {
      icon: <FaJava title="Java" />,
      title: "Java",
    },
    {
      icon: <FaDatabase title="SQL" />,
      title: "SQL",
    },
    {
      icon: <SiCsharp title="C#" />,
      title: "C#",
    },
    {
      icon: <SiMicrosoftsqlserver title="SQL Server" />,
      title: "SQL Server",
    },
    {
      icon: <SiJquery title="jQuery" />,
      title: "jQuery",
    },
    {
      icon: <SiPython title="Python" />,
      title: "Python",
    },
    {
      icon: <SiKotlin title="Kotlin" />,
      title: "Kotlin",
    },
    {
      icon: <DiJira title="Jira" />,
      title: "Jira",
    },
    {
      icon: <SiVisualstudio title="Visual Studio &amp; Visual Studio Code" />,
      title: "Visual Studio/Code",
    },
    {
      icon: <SiAdobephotoshop title="Photoshop CS3" />,
      title: "Photoshop CS3",
    },
    {
      icon: <SiBootstrap title="Bootstrap" />,
      title: "Bootstrap",
    },
    {
      icon: <SiAngularjs title="AngularJs" />,
      title: "AngularJs",
    },
    {
      icon: <FaLess title="Less" />,
      title: "Less",
    },
    {
      icon: <SiCplusplus title="C++" />,
      title: "C++",
    },
    {
      icon: <FaGithub title="Github" />,
      title: "Github",
    },
    {
      icon: <FaGitlab title="Gitlab" />,
      title: "Gitlab",
    },
  ];

  return (
    <div className="row skills">
      {icons.map((i) => {
        return (
          <div className="col-4 text-center" key={i.title}>
            <div style={{ fontSize: "2em" }}>{i.icon}</div>
            <p className="highlight">{i.title}</p>
          </div>
        );
      })}
    </div>
  );
};
