import React from "react";
// import Ratio from "react-bootstrap/Ratio";
import { getYoutubeVideos } from "./http";
import Layout from "./Layout";
import Loading from "./Loading";

export default class ReligiousVideos extends React.Component {
  constructor() {
    super();
    this.state = {
      videos: [],
      loadingComplete: false,
    };
    this.loadingTimer = null;
  }
  componentDidMount() {
    getYoutubeVideos()
      .then((items) => {
        const videos = [];
        for (const item of items.data) {
          videos.push({
            webContentLink: item.webContentLink,
            title: item.name,
            description: item.description,
          });
        }

        this.setState({ videos });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  renderVideos() {
    const { videos } = this.state;

    if (!videos) return null;

    return (
      <div className="container-fluid">
        <div className="typewriter">
          <h1>Videos</h1>
        </div>
        <hr />
        <Loading show={videos.length === 0} />
        {videos.map((v) => {
          return (
            <div className="video-container" key={v.title}>
              <b>{v.title}</b>
              <div className="responsive-video">
                {/* <h3>{v.description}</h3> */}
                <iframe
                  src={v.webContentLink}
                  title={v.title}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullScreen
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    return (
      <Layout>
        <br />
        {this.renderVideos()}
      </Layout>
    );
  }
}
