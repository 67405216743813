import React from "react";

export default class Loading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: this.props.show,
    };
    this.timer = null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show === true && this.props.show === false) {
      this.timer = setTimeout(() => this.setState({ show: false }), 2000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    if (this.state.show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return (
      <div
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          top: "0",
          left: "0",
          zIndex: "1",
          justifyContent: "center",
          alignItems: "center",
          display: this.state.show ? "flex" : "none",
          flexDirection: "row",
          backgroundColor: "#fff",
          opacity: "0.8",
        }}
      >
        <div className="spinner-grow text-primary" role="status">
          <span className="sr-only">...</span>
        </div>
        <div className="spinner-grow text-secondary" role="status">
          <span className="sr-only">...</span>
        </div>
        <div className="spinner-grow text-success" role="status">
          <span className="sr-only">...</span>
        </div>
        <div className="spinner-grow text-danger" role="status">
          <span className="sr-only">...</span>
        </div>
        <div className="spinner-grow text-warning" role="status">
          <span className="sr-only">...</span>
        </div>
        <div className="spinner-grow text-info" role="status">
          <span className="sr-only">...</span>
        </div>
        <div className="spinner-grow text-dark" role="status">
          <span className="sr-only">...</span>
        </div>
      </div>
    );
  }
}
