import React from "react";
// import { getStackoverflowUser } from "./http";
import Layout from "./Layout";
import { FaOm, FaFileAlt, FaCode, FaVideo, FaImages } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Parallax } from "react-parallax";
import { Experience } from "./experience";

export default class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      stackoverflowUser: null,
    };

    this.employmentStartYear = 2011;
    this.priesthoodStartYear = 2004;
  }

  componentDidMount() {
    // getStackoverflowUser(process.env.REACT_APP_STACKOVERFLOW_USERID).then(
    //   (response) => {
    //     if (response && response.data && response.data.items)
    //       this.setState({ stackoverflowUser: response.data.items[0] });
    //   }
    // );
  }

  render() {
    // const { stackoverflowUser } = this.state;
    return (
      <Layout>
        <div className="home container-fluid">
          <br />
          <h1
            className="text-center"
            style={{
              fontWeight: "bold",
              fontSize: "4em",
            }}
          >
            Vishwaram <span style={{ color: "orange" }}>Maharaj</span>
          </h1>
          <div className="text-center">
            <div>
              <FaCode className="highlight" style={{ fontSize: "2em" }} />{" "}
              Programmer, Priest, Traveler, Gamer
              <FaCode className="highlight" style={{ fontSize: "2em" }} />
            </div>
          </div>
          <Parallax
            bgImage={`${process.env.PUBLIC_URL}/assets/profile6.JPEG`}
            bgImageAlt="Vishwaram"
          >
            <div className="text-center" style={{ height: "400px" }}></div>
          </Parallax>
          <div className="section">
            <div className="row">
              <div className="col-md-6">
                <div className="card bg-dark text-white">
                  <div className="card-header text-center">
                    {/* <img
                      src={`${process.env.PUBLIC_URL}/assets/profile2.png`}
                      class="rounded"
                      alt="profile"
                      width={190}
                      height={200}
                      style={{ border: "1px solid #fff" }}
                    /> */}
                    <h3 className="highlight">
                      Software Engineer{" "}
                      <small
                        className="text-white"
                        style={{ fontSize: ".5em" }}
                      >
                        {new Date().getFullYear() - this.employmentStartYear}{" "}
                        years
                      </small>
                    </h3>
                    <Link to="/professional/resume" className="">
                      <FaFileAlt style={{ fontSize: "2em" }} />
                      View My Resume
                    </Link>
                  </div>
                  <div className="card-body">
                    <p className="card-text">
                      I love designing and building distributed systems, working
                      on speeding up algorithms, and solving problems in
                      multiple programming languages.
                    </p>
                    <Experience />
                    <figure>
                      <blockquote
                        style={{
                          padding: "10px",
                          margin: "20px",
                        }}
                      >
                        <p>
                          A designer knows he has arrived at perfection not when
                          there is no longer anything to add, but when there is
                          no longer anything to take away.
                        </p>
                        <figcaption className="blockquote-footer">
                          Antoine de Saint-Exupery
                        </figcaption>
                      </blockquote>
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="card text-white bg-dark"
                  style={{ color: "#000" }}
                >
                  <div className="card-header text-center">
                    {/* <img
                      src={`${process.env.PUBLIC_URL}/assets/religious.jpg`}
                      class="rounded"
                      alt="profile"
                      width={150}
                      height={200}
                      style={{ border: "1px solid #fff" }}
                    /> */}
                    <h3 className="highlight">
                      Pundit/Hindu Priest{" "}
                      <small
                        className="text-white"
                        style={{ fontSize: ".5em" }}
                      >
                        {new Date().getFullYear() - this.priesthoodStartYear}{" "}
                        years
                      </small>
                    </h3>
                    <Link to="/religious/videos">
                      <FaVideo style={{ fontSize: "2em" }} />
                      Videos
                    </Link>
                    &nbsp;
                    <Link to="/religious/photos">
                      <FaImages style={{ fontSize: "2em" }} />
                      Photos
                    </Link>
                  </div>
                  <div className="card-body">
                    <p className="card-text">
                      I have been steeply involved in hindu culture and
                      philosophy since birth as my entire family are also hindu
                      priests and scholars. I began studying how to practice
                      hindu rituals in 2004 trained by my father Dr. Gourinarine
                      Maharaj and later on by my Guru Ji Pt. Anand Maharaj. I
                      have since participated and conducted hundreds of yajnas,
                      pujas, weddings, and various celebrations.
                    </p>

                    <div className="row highlight">
                      <FaOm title="Om" style={{ fontSize: "2em" }} />
                    </div>
                    <figure>
                      <blockquote
                        style={{
                          padding: "10px",
                          margin: "20px",
                        }}
                      >
                        <p>
                          Our life and death, our joy and pain, Anger and fear,
                          and loss and gain, Each thing that is, in every state,
                          All is the work of none but Fate.
                        </p>
                        <figcaption className="blockquote-footer">
                          Valmiki Ramayana English translation by Ralph T. H.
                          Griffith
                        </figcaption>
                      </blockquote>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
