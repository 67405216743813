import React from "react";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import {
  FaInstagram,
  FaLinkedin,
  FaFacebookSquare,
  FaGithubSquare,
  FaYoutubeSquare,
  FaStackOverflow,
  FaRegCopyright,
} from "react-icons/fa";
// import logo from "./logo.svg";
import "./Layout.css";

export default class Layout extends React.Component {
  render() {
    return (
      <>
        <Navbar expand="md" bg="dark" variant="dark" fixed="top">
          <Container>
            <Navbar.Brand href={"/home"}>
              <b>
                V<span style={{ color: "orange" }}>M</span>
              </b>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav" className="me-auto">
              <Nav>
                <Nav.Link href="/home">Home</Nav.Link>
                <Nav.Link href="/professional/resume">Professional</Nav.Link>
                <Nav.Link href="/religious/videos">Videos</Nav.Link>
                <Nav.Link href="/religious/photos">Photos</Nav.Link>
                {/* <NavDropdown title="Professional" id="professional-menu">
                  <NavDropdown.Item href="/professional/resume">
                    Resume
                  </NavDropdown.Item>
                </NavDropdown> */}
                {/* <NavDropdown title="Religious" id="religious-menu">
                  <NavDropdown.Item href="/religious/calendar">
                    Calendar
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/religious/videos">
                    Videos
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/religious/photos">
                    Photos
                  </NavDropdown.Item>
                </NavDropdown> */}
                <Nav.Link href="/projects">Projects</Nav.Link>
                {/* <Nav.Link href="/merch">Merch</Nav.Link> */}
              </Nav>
            </Navbar.Collapse>
            <Navbar.Collapse className="justify-content-end">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/vishwaram-maharaj-98110173"
              >
                <FaLinkedin title="LinkedIn" className={"social-media"} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/vishwaram"
              >
                <FaFacebookSquare title="Facebook" className={"social-media"} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/indian.gorilla/"
              >
                <FaInstagram title="Instagram" className={"social-media"} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/c/vishwarammaharaj"
              >
                <FaYoutubeSquare title="Youtube" className={"social-media"} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://github.com/vishwaramm"
              >
                <FaGithubSquare title="Git Hub" className={"social-media"} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://stackoverflow.com/users/2488939/the-mahahaj"
              >
                <FaStackOverflow
                  title="StackOverflow"
                  className={"social-media"}
                />
              </a>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        {this.props.children}
        <div className="text-center" style={{ padding: "20px" }}>
          <small>
            Copyright{" "}
            <FaRegCopyright
              style={{ fontSize: ".8em", top: "-1px", position: "relative" }}
            />{" "}
            {new Date().getFullYear()}{" "}
            <b style={{ color: "orange" }}>Vishwaram Maharaj</b>. All rights
            reserved.
          </small>
        </div>
      </>
    );
  }
}
