import React from "react";
import Layout from "./Layout";

export default class Merch extends React.Component {
  render() {
    return (
      <Layout>
        <br />
        <div className="typewriter">
          <h1>Merch</h1>
        </div>
      </Layout>
    );
  }
}
