import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { getDailyHistory, getIntradayData, getSymbolHistory } from "../../http";

export default class PriceChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };

    this.cache = {};
  }

  componentDidMount() {
    const elem = document.getElementById("ch-1d");
    elem.click();
  }

  onTabClick = (e) => {
    const elem = e.target;
    const txt = elem.textContent || elem.innerText;
    let promise = null;
    const key = `${this.props.ticker}_${txt}`;
    if (this.cache[key]) {
      this.parseAndSetupChartData(this.cache[key], txt);
      return;
    }

    switch (txt) {
      case "1D":
        promise = getIntradayData;
        break;
      case "5D":
      case "1M":
        promise = getDailyHistory;
        break;
      case "6M":
      case "YTD":
        promise = getSymbolHistory;
        break;
      default:
        break;
    }

    if (!promise) return; // TODO: ???

    promise(this.props.ticker).then((resp) => {
      this.cache[key] = resp.data;
      this.parseAndSetupChartData(this.cache[key], txt);
    });
  };

  parseAndSetupChartData(data, mode) {
    if (!data) return null;

    let timeSeriesPropName = "Time Series";
    const today = new Date();
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    for (const prop in data) {
      if (prop.indexOf("Time Series") > -1) {
        timeSeriesPropName = prop;
        break;
      }
    }

    if (!data[timeSeriesPropName]) return;

    const results = [];
    const keys = Object.keys(data[timeSeriesPropName]).reverse();
    for (let i = 0; i < keys.length; i++) {
      const prop = keys[i];
      const d = new Date(`${prop.substring(5)}-${prop.substring(0, 4)}`);

      const period = data[timeSeriesPropName][prop];
      const r = {};
      for (const name in period) {
        if (Object.prototype.hasOwnProperty.call(period, name)) {
          const newKey = name.replace(/\d. /g, "");
          r[newKey] = period[name];
        }
      }
      switch (mode) {
        case "1D":
          const parts = prop.split(" ");
          r.xaxis = parts[1];
          today.setHours(0, 0, 0, 0);
          const start = new Date(today.toDateString() + " 09:30");
          const end = new Date(today.toDateString() + " 16:00");
          // skip any trades before or after market hours
          if (d < start || d > end) continue;
          break;
        case "5D":
          let stopDate = new Date();
          stopDate.setDate(today.getDate() - 5);

          // we only want 5 days worth of data
          if (d < stopDate) continue;
          r.xaxis = days[d.getDay()];
          break;
        case "1M":
          const lastMonth = new Date();
          const m = lastMonth.getMonth();
          lastMonth.setMonth(lastMonth.getMonth() - 1);

          // If still in same month, set date to last day of
          // previous month
          if (lastMonth.getMonth() === m) lastMonth.setDate(0);
          lastMonth.setHours(0, 0, 0, 0);

          // we only want the past month's data
          if (d < lastMonth) continue;
          r.xaxis = String(d);
          break;
        case "6M":
          const lastSixMonths = new Date();
          const sm = lastSixMonths.getMonth();
          lastSixMonths.setMonth(lastSixMonths.getMonth() - 6);

          // If still in same month, set date to last day of
          // previous month
          if (lastSixMonths.getMonth() === sm) lastSixMonths.setDate(0);
          lastSixMonths.setHours(0, 0, 0, 0);

          // we only want the past 6 months data
          if (d < lastSixMonths) continue;
          r.xaxis = String(d);
          break;
        case "YTD":
          const lastYear = new Date();
          lastYear.setFullYear(lastYear.getFullYear() - 1);
          // we only want the past 12 months data
          if (d < lastYear) continue;
          r.xaxis = String(d);
          break;
        default:
          r.xaxis = prop;
          break;
      }
      results.push(r);
    }

    this.setState({
      data: results,
    });
  }

  render() {
    const { data } = this.state;

    return (
      <>
        <div className="chart-button-container" style={{ textAlign: "right" }}>
          <button
            id="ch-1d"
            type="button"
            className="btn btn-link"
            onClick={this.onTabClick}
          >
            1D
          </button>
          <button
            id="ch-5d"
            type="button"
            className="btn btn-link"
            onClick={this.onTabClick}
          >
            5D
          </button>
          <button
            id="ch-1m"
            type="button"
            className="btn btn-link"
            onClick={this.onTabClick}
          >
            1M
          </button>
          <button
            id="ch-6m"
            type="button"
            className="btn btn-link"
            onClick={this.onTabClick}
          >
            6M
          </button>
          <button
            id="ch-ytd"
            type="button"
            className="btn btn-link"
            onClick={this.onTabClick}
          >
            YTD
          </button>
        </div>
        {data && (
          <ResponsiveContainer width={"100%"} height={300}>
            <LineChart
              width={500}
              height={400}
              data={data}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="xaxis" />
              <YAxis />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="close"
                stroke="#8884d8"
                fill="#8884d8"
              />
              <Legend />
            </LineChart>
          </ResponsiveContainer>
        )}
      </>
    );
  }
}
