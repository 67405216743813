import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./Home";
import Projects from "./Projects";
import ReligiousPhotos from "./ReligiousPhotos";
import ReligiousVideos from "./ReligiousVideos";
import Resume from "./Resume";
import Calendar from "./Calendar";
import FinancialRisk from "./apps/financial-risk/FinancialRisk";
import RandomGenerator from "./apps/maze-gen/random-generator";
import Merch from "./Merch";

function Routes() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/home" exact component={Home} />
        <Route exact path="/projects" component={Projects} />
        <Route path="/religious/calendar" component={Calendar} />
        <Route path="/religious/videos" component={ReligiousVideos} />
        <Route path="/religious/photos" component={ReligiousPhotos} />
        <Route path="/professional/resume" component={Resume} />
        <Route path="/apps/financial-risk" component={FinancialRisk} />
        <Route path="/apps/maze-generator" component={RandomGenerator} />
        <Route path="/merch" component={Merch} />
      </Switch>
    </Router>
  );
}

export default Routes;
