import axios from "axios";

export const getBasePath = () => {
  let basePath = `http://${window.location.hostname}:8080`;
  if (window.location.href.indexOf("heroku") > -1) {
    basePath = "https://vishwaramcom-server.herokuapp.com/";
  } else if (window.location.href.indexOf("vishwaram.com") > -1) {
    basePath = "https://api.vishwaram.com";
  }
  return basePath;
};

export const getStackoverflowUser = (userId) => {
  return axios
    .get(`https://api.stackexchange.com/users/${userId}?site=stackoverflow`)
    .catch((error) => {
      console.error(error);
    });
};

// export const getYoutubeVideos = () => {
//   const maxResults = 50;
//   const parts = "contentDetails,snippet,status";
//   return axios.get(
//     `https://www.googleapis.com/youtube/v3/playlistItems?part=${parts}&maxResults=${maxResults}&playlistId=${process.env.REACT_APP_YT_PLAYLIST_ID}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
//   );
// };
export const getYoutubeVideos = () => {
  const authHeader = getAuthHeader();
  return axios.get(`${getBasePath()}/api/google/youtubeVideos`, {
    auth: authHeader,
  });
};

const getAuthHeader = () => {
  return {
    username: process.env.REACT_APP_SERVER_USERNAME,
    password: process.env.REACT_APP_SERVER_PASSWORD,
  };
};

export const getGDriveFiles = () => {
  const driveUrl = `${getBasePath()}/api/google/religiousPics`;
  const authHeader = getAuthHeader();
  return axios.get(driveUrl, {
    auth: authHeader,
  });
};

export const getGDriveFile = (id) => {
  const driveUrl = `${getBasePath()}/api/google/file?id=${id}`;
  const authHeader = getAuthHeader();
  return axios.get(driveUrl, {
    auth: authHeader,
    responseType: "arraybuffer",
  });
};

export const searchSymbol = (ticker) => {
  const apiUrl = `${process.env.REACT_APP_STOCK_API}/query?function=SYMBOL_SEARCH&keywords=${ticker}&apikey=${process.env.REACT_APP_STOCK_API_KEY}`;
  return axios.get(apiUrl);
};

export const getIntradayData = (ticker) => {
  const apiUrl = `${process.env.REACT_APP_STOCK_API}/query?function=TIME_SERIES_INTRADAY&symbol=${ticker}&interval=5min&apikey=${process.env.REACT_APP_STOCK_API_KEY}`;
  return axios.get(apiUrl);
};

export const getDailyHistory = (ticker) => {
  const apiUrl = `${process.env.REACT_APP_STOCK_API}/query?function=TIME_SERIES_DAILY&symbol=${ticker}&apikey=${process.env.REACT_APP_STOCK_API_KEY}`;
  return axios.get(apiUrl);
};

export const getSymbolHistory = (ticker) => {
  const apiUrl = `${process.env.REACT_APP_STOCK_API}/query?function=TIME_SERIES_WEEKLY&symbol=${ticker}&apikey=${process.env.REACT_APP_STOCK_API_KEY}`;
  return axios.get(apiUrl);
};

export const getCompanyOverview = (ticker) => {
  const apiUrl = `${process.env.REACT_APP_STOCK_API}/query?function=OVERVIEW&symbol=${ticker}&apikey=${process.env.REACT_APP_STOCK_API_KEY}`;
  return axios.get(apiUrl);
};

export const getSymbolQuote = (ticker) => {
  const apiUrl = `${process.env.REACT_APP_STOCK_API}/query?function=GLOBAL_QUOTE&symbol=${ticker}&apikey=${process.env.REACT_APP_STOCK_API_KEY}`;
  return axios.get(apiUrl);
};

export const searchInstrument = (ticker) => {
  const apiUrl = `${process.env.REACT_APP_FINNHUB_API}/search?q=${ticker}&token=${process.env.REACT_APP_FINNHUB_API_KEY}`;
  return axios.get(apiUrl);
};

export const getInstrumentQuote = (ticker) => {
  const apiUrl = `${process.env.REACT_APP_FINNHUB_API}/quote?symbol=${ticker}&token=${process.env.REACT_APP_FINNHUB_API_KEY}`;
  return axios.get(apiUrl);
};

export const getInstrumentMetrics = (ticker) => {
  const apiUrl = `${process.env.REACT_APP_FINNHUB_API}/stock/metric?symbol=${ticker}&metric=all&token=${process.env.REACT_APP_FINNHUB_API_KEY}`;
  return axios.get(apiUrl);
};
