import React from "react";
import { getGDriveFiles, getGDriveFile } from "./http";
import Layout from "./Layout";
import Loading from "./Loading";

export default class ReligiousPhotos extends React.Component {
  constructor() {
    super();
    this.state = {
      drivePhotos: [],
      accessToken: null,
      loaded: false,
    };
    this.imgLoaded = 0;
  }
  componentDidMount() {
    getGDriveFiles()
      .then((resp) => {
        for (const index in resp.data) {
          const item = resp.data[index];
          this.displayProtectedImage(item.id).then((dataUrl) => {
            const drivePhotos = this.state.drivePhotos;
            drivePhotos.push({
              data: item,
              dataUrl,
            });

            this.setState({ drivePhotos });
          });
        }
      })
      .catch((err) => console.error(err));
  }

  arrayBufferToBase64(buffer) {
    return btoa(String.fromCharCode(...new Uint8Array(buffer)));
  }

  displayProtectedImage = async (id) => {
    if (!id) return null;
    // Fetch the image.
    const response = await getGDriveFile(id);

    // Convert the data to Base64 and build a data URL.
    const base64 = Buffer.from(response.data, "binary").toString("base64");
    const dataUrl = `data:image/png;base64,${base64}`;

    return dataUrl;
  };

  setLoaded = () => {
    this.imgLoaded++;

    if (this.imgLoaded === this.state.drivePhotos.length) {
      this.setState({ loaded: true });
    }
  };

  renderPhotos() {
    const { drivePhotos } = this.state;
    if (!drivePhotos || drivePhotos.length === 0) return null;
    let index = -1;
    return (
      <div className="container-fluid">
        <div
          id="religious-photos-carousel"
          className="carousel slide carousel-fade"
          data-ride="carousel"
        >
          <div className="carousel-inner">
            {drivePhotos.map((p) => {
              index++;
              return (
                <div className={`carousel-item ${index === 0 ? "active" : ""}`}>
                  <p className="text-center">
                    {index + 1} of {drivePhotos.length}
                  </p>
                  <img
                    alt={p.data.name}
                    className="d-block"
                    style={{
                      margin: "0 auto",
                      height: window.innerHeight - 100,
                    }}
                    src={p.dataUrl}
                    onLoad={this.setLoaded()}
                  />
                </div>
              );
            })}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#religious-photos-carousel"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#religious-photos-carousel"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Layout>
        <Loading show={!this.state.loaded} />
        <br />
        <div className="typewriter">
          <h1>Photos</h1>
        </div>
        <hr />
        {this.renderPhotos()}
      </Layout>
    );
  }
}
