function formatPropertyName(str) {
  let newStr = "";
  for (let i = 0; i < str.length; i++) {
    const c = str[i];
    const upperCase = c.toUpperCase();
    if (i === 0) {
      newStr += upperCase;
      continue;
    } else if (c === upperCase) {
      // add space after upper case letter
      newStr += " ";
    }
    newStr += c;
  }

  return newStr;
}

const _customMetrics = new Map();
_customMetrics.set("netMargin", {
  display: "Net Profit Margin",
  calc: "percent",
});
_customMetrics.set("eps", {
  display: "EPS",
});

_customMetrics.set("ebitPerShare", {
  display: "EBIT Per Share",
});

_customMetrics.set("operatingMargin", {
  display: "Operating Margin",
  calc: "percent",
});

_customMetrics.set("pretaxMargin", {
  display: "Pretax Margin",
  calc: "percent",
});

_customMetrics.set("sgaToSale", {
  display: "SGA To Sale",
});
_customMetrics.set("10DayAverageTradingVolume", {
  display: "10 Day Average Trading Volume",
});
_customMetrics.set("13WeekPriceReturnDaily", {
  display: "13 Week Price Return Daily",
});
_customMetrics.set("26WeekPriceReturnDaily", {
  display: "26 Week Price Return Daily",
});
_customMetrics.set("3MonthAverageTradingVolume", {
  display: "3 Month Average Trading Volume",
});
_customMetrics.set("52WeekHigh", {
  display: "52 Week High",
});
_customMetrics.set("52WeekLow", {
  display: "52 Week Low",
});
_customMetrics.set("52WeekPriceReturnDaily", {
  display: "52 Week Price Return Daily",
});
_customMetrics.set("5DayPriceReturnDaily", {
  display: "5 Day Price Return Daily",
});
_customMetrics.set("10DayAverageTradingVolume", {
  display: "10 Day Average Trading Volume",
});
_customMetrics.set("10DayAverageTradingVolume", {
  display: "10 Day Average Trading Volume",
});

export default function Metric(props) {
  if (!props.value) return null;
  let label = formatPropertyName(props.name);
  let value = props.value;
  let suffix = "";
  if (_customMetrics.has(props.name)) {
    const custom = _customMetrics.get(props.name);
    label = custom.display;
    switch (custom.calc) {
      case "percent":
        value = value * 100;
        suffix = "%";
        break;
      default:
        break;
    }
  }
  return (
    <li
      className="list-group-item d-flex justify-content-between align-items-start"
      key={label}
    >
      <div className="ms-2 me-auto">
        <div className="fw-bold">{label}</div>
        {`${value.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}${suffix}`}
      </div>
    </li>
  );
}
