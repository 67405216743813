import React from "react";
import Layout from "./Layout";

export default class Calendar extends React.Component {
  render() {
    return (
      <Layout>
        <br />
        <div className="typewriter">
          <h1>Calendar</h1>
        </div>
        <iframe
          src="https://calendar.google.com/calendar/embed?src=n2k8rc3insqp26g7t9vkpkq2pg%40group.calendar.google.com&amp;ctz=America%2FNew_York"
          style={{ border: "0", width: "100%", height: "100vh" }}
          frameborder="0"
          scrolling="no"
          title="Google Calendar"
        ></iframe>
      </Layout>
    );
  }
}
