import React from "react";
import { getInstrumentMetrics, getInstrumentQuote } from "../../http";
import Search from "./search";
import QuoteData from "./quoteData";
import PriceChart from "./price-chart";
import Metrics from "./metrics";

export default class FinancialRisk extends React.Component {
  constructor() {
    super();
    this.state = {
      instrument: null,
      selected: "",
      autocompleteError: "",
    };
  }

  componentDidMount() {}

  getQuoteData = () => {
    const { selected } = this.state;

    if (!selected) {
      this.setState({
        autocompleteError:
          "Please select an item from the search menu in order to continue.",
      });
      return;
    }

    let ticker = selected;

    if (ticker.indexOf(":") > -1) {
      ticker = ticker.split(":")[0];
    }
    ticker = ticker.toUpperCase();

    getInstrumentQuote(ticker).then(async (resp) => {
      if (!resp || !resp.data) {
        console.error("An error occured while trying to get quote data");
        return;
      }

      const metricResp = await getInstrumentMetrics(ticker);

      const instrument = {
        quoteData: resp.data,
        metrics: metricResp.data,
      };

      this.setState({
        instrument,
        selected: "",
        autocompleteError: "",
      });
    });
  };

  render() {
    const { instrument, autocompleteError } = this.state;
    return (
      <div className="container">
        <div className="row">
          <h1>Stock Market Risk</h1>
          <Search
            onSelectionChange={(newValue) =>
              this.setState({ selected: newValue })
            }
            onSearch={this.getQuoteData}
            error={autocompleteError}
          />
          <QuoteData instrument={instrument} />
          <div className="row">
            <div className="col-sm-6">
              <Metrics instrument={instrument} />
            </div>
            <div className="col-sm-6">
              {instrument && <PriceChart ticker={instrument.metrics.symbol} />}
            </div>
          </div>
          <div className="instrument-analysis"></div>
        </div>
      </div>
    );
  }
}
