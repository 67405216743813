import Metric from "./metric";

export default function Metrics(props) {
  if (!props.instrument) return null;
  return (
    <ol className="list-group">
      <li
        className="list-group-item d-flex justify-content-between align-items-start"
        key={"open"}
      >
        <div className="ms-2 me-auto">
          <div className="fw-bold">Open</div>
          {props.instrument.quoteData.o.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      </li>
      {Object.keys(props.instrument.metrics.series.annual).map((prop) => {
        const current = props.instrument.metrics.series.annual[prop];
        if (!current || current.length === 0) return null;
        return (
          <Metric
            value={current[current.length - 1].v}
            name={prop}
            key={prop}
          />
        );
      })}
      {Object.keys(props.instrument.metrics.metric).map((prop) => {
        if (prop.indexOf("Date") > -1) return null;
        const current = props.instrument.metrics.metric[prop];
        if (!current || current.length === 0) return null;
        return <Metric value={current} name={prop} key={prop} />;
      })}
    </ol>
  );
}
