import React from "react";
import { searchInstrument } from "../../http";
import { Autocomplete, TextField } from "@mui/material";
import { debounce } from "lodash";

export default class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lookup: [],
      loading: false,
      selected: "",
    };
    this.debouncedSearch = debounce((e) => this.onKeyUp(e), 1000);
  }

  onAutocompleteChange = (event, newValue) => {
    const { onSelectionChange } = this.props;
    this.setState(
      {
        selected: newValue,
      },
      () => {
        onSelectionChange(newValue);
      }
    );
  };

  onKeyUp = (e) => {
    const target = e.target;
    this.sendSymbolSearch(target.value);
  };

  sendSymbolSearch(symbol) {
    this.setState({ loading: true });
    searchInstrument(symbol).then((resp) => {
      // set data for autocomplete window
      const lookup = [];
      for (const i in resp.data.result) {
        const item = resp.data.result[i];
        // no support for anything else in the free api
        if (item.type !== "Common Stock") continue;

        lookup.push(`${item.symbol}: ${item.description}`);
      }
      this.setState({
        lookup,
        loading: false,
      });
    });
  }

  render() {
    const { lookup, loading } = this.state;
    return (
      <div
        className="top-search row"
        style={{
          backgroundColor: "#fff",
          padding: "10px",
          borderRadius: "4px",
        }}
      >
        <div className="col-sm-9">
          <Autocomplete
            id="ticker-search"
            freeSolo
            options={lookup}
            loading={loading}
            noOptionsText={"No Results Found"}
            onChange={this.onAutocompleteChange}
            selectOnFocus
            renderInput={(params) => (
              <TextField
                {...params}
                onChange={this.debouncedSearch}
                label="Symbol Search"
              />
            )}
          />
          <p
            style={{
              color: "Red",
              display: this.props.error ? "block" : "none",
            }}
          >
            {this.props.error}
          </p>
        </div>
        <div className="col-sm-3">
          <div className="d-grid gap-2">
            <button
              type="button"
              className="btn btn-lg btn-success"
              onClick={this.props.onSearch}
            >
              Search
            </button>
          </div>
        </div>
      </div>
    );
  }
}
