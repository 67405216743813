import React from "react";
import { Octokit } from "@octokit/rest";
import Layout from "./Layout";
import {
  FaGithub,
  FaMobileAlt,
  FaExternalLinkAlt,
  FaGlobe,
} from "react-icons/fa";

export default class Projects extends React.Component {
  constructor() {
    super();
    this.state = {
      githubProjects: [],
    };
  }

  componentDidMount() {
    const octokit = new Octokit({
      auth: process.env.REACT_APP_GITHUB_TOKEN,
      userAgent: "vishwaram.com",
    });

    octokit.rest.repos
      .listForUser({
        username: "vishwaramm",
      })
      .then((resp) => {
        if (resp && resp.data) {
          this.setState({ githubProjects: resp.data });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const { githubProjects } = this.state;
    return (
      <Layout>
        <br />
        <div className="container-fluid projects">
          <div className="typewriter">
            <h1>Projects</h1>
          </div>
          <br />
          <br />
          <h2>
            <FaGlobe /> Websites
          </h2>
          <div className="list-group">
            <div className="list-group-item bg-dark text-white">
              <a
                href="http://www.gourishankarmandir.com"
                target="_blank"
                rel="noreferrer"
              >
                <FaExternalLinkAlt /> Gouri Shankar Mandir
              </a>
            </div>
          </div>
          <h2>
            <FaMobileAlt /> Mobile Apps
          </h2>
          <div className="list-group">
            <div className="list-group-item bg-dark text-white">
              <div className="row">
                <div className="col-md-2 col-sm-4">
                  <img
                    alt="Body Analyzer App"
                    src="https://is3-ssl.mzstatic.com/image/thumb/Purple124/v4/92/a2/4d/92a24dfc-1331-de5d-2896-0225387d4c09/AppIcon-0-0-1x_U007emarketing-0-0-0-7-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/460x0w.webp"
                    style={{ width: "100%" }}
                  />
                </div>

                <div className="col-md-10 col-sm-8">
                  <h2 className="highlight">Body Analyzer</h2>
                  <p>Perform a full body analysis with over 19 calculations.</p>
                  {/* <ol style={{ listStyleType: "square", textAlign: "left" }}>
                <li>Enter your measurements and see how healthy you are.</li>
                <li>Check how many grams of protein you need per day.</li>
                <li>View how many calories you should eat per day.</li>
                <li>
                  View how many ounces of water you should partake of daily.
                </li>
                <li>View over 19 different calculations.</li>
              </ol> */}
                  <a
                    href="https://apps.apple.com/us/app/body-analyzer-plus/id1489053077?itsct=apps_box_badge&amp;itscg=30200"
                    style={{
                      display: "inline-block",
                      overflow: "hidden",
                      borderRadius: "13px",
                      width: "250px",
                      height: "83px",
                    }}
                  >
                    <img
                      src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/white/en-us?size=250x83&amp;releaseDate=1574640000&mp;h=75a86902358836263e20d5dea26f0726"
                      alt="Download on the App Store"
                      style={{
                        borderRadius: "13px",
                        width: "250px",
                        height: "83px",
                      }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <br />
          <h2>
            <FaGithub />
            &nbsp;Github
          </h2>
          <div className="list-group">
            {githubProjects.map((p) => {
              if (p.private) return null;
              return (
                <div
                  className="list-group-item bg-dark text-white"
                  key={p.name}
                >
                  <div className="row">
                    <div className="col">
                      <a href={p.html_url} target="_blank" rel="noreferrer">
                        <FaGithub style={{ fontSize: "2em" }} />
                      </a>
                    </div>
                    <div className="col-11">
                      <b className="highlight">{p.name}</b>{" "}
                      <small>{p.language}</small>{" "}
                      <small style={{ fontSize: ".8em" }}>
                        {p.description && <>{p.description}</>}
                      </small>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <br />
          <h2>Experimental Apps</h2>
          <div className="list-group">
            <div className="list-group-item bg-dark text-white">
              <a href={"/apps/maze-generator"}>Maze Generator</a>
            </div>
            <div className="list-group-item bg-dark text-white">
              <a href={"/apps/financial-risk"}>Finance Calculator</a>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
