import React from "react";

export default class RandomGenerator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      grid: null,
      path: [],
    };

    this.rowDirections = [0, 1, 0, -1];
    this.colDirections = [1, 0, -1, 0];
  }

  createMaze(size) {
    // create array of 1s meaning every path is blocked
    const twoDArray = [];

    for (let i = 0; i < size; i++) {
      const innerArr = [];
      for (let j = 0; j < size; j++) {
        innerArr.push(1);
      }
      twoDArray.push(innerArr);
    }

    return twoDArray;
  }

  randomDfs(size) {
    const grid = this.createMaze(size);
    const stack = [];
    const path = [];

    stack.push({ r: 0, c: 0 });

    while (stack.length > 0) {
      const coords = stack.pop();
      const row = coords.r;
      const col = coords.c;

      if (
        row > grid.length - 1 ||
        row < 0 ||
        col < 0 ||
        col > grid[row].length - 1 ||
        grid[row][col] === 0
      )
        continue;

      grid[row][col] = 0;
      path.push(coords);

      // randomly pick a neighbor
      const hashMap = {};
      while (Object.keys(hashMap).length < 4) {
        const i = this.getRandomNumber(4);
        if (hashMap[i]) continue;

        hashMap[i] = 1;

        stack.push({
          r: row + this.rowDirections[i],
          c: col + this.colDirections[i],
        });
      }
    }
    this.setState({
      grid,
      path,
    });
  }

  getDirectionFromCoord(coord1, coord2) {
    if (!coord1 || !coord2) return "";
    const rMove = coord2.r - coord1.r;
    const cMove = coord2.c - coord1.c;

    if (rMove === -1) {
      return "up";
    } else if (rMove === 1) {
      return "down";
    }

    if (cMove === -1) {
      return "left";
    } else if (cMove === 1) {
      return "right";
    }

    return "";
  }

  constructPathRepresentation(grid, path) {
    /*
      final object:
      borderRight
      borderLeft
      borderTop
      borderBottom

      populate data structure with all coords adjacent to a particular coordinate
      loop through path array
      track previous coord
      add coord to data structure

0: {r: 0, c: 0}
1: {r: 0, c: 1}
2: {r: 0, c: 2}
3: {r: 0, c: 3}
4: {r: 1, c: 3}
5: {r: 2, c: 3}
6: {r: 3, c: 3}
7: {r: 3, c: 4}
8: {r: 3, c: 5}
9: {r: 4, c: 5}
10: {r: 5, c: 5}
11: {r: 5, c: 6}
12: {r: 6, c: 6}
13: {r: 6, c: 7}
14: {r: 5, c: 7}
15: {r: 5, c: 8}
16: {r: 6, c: 8}
17: {r: 6, c: 9}
18: {r: 5, c: 9}
19: {r: 5, c: 10}
20: {r: 5, c: 11}
21: {r: 4, c: 11}
22: {r: 3, c: 11}
23: {r: 3, c: 10}
24: {r: 2, c: 10}
25: {r: 1, c: 10}
26: {r: 0, c: 10}
27: {r: 0, c: 9}
28: {r: 1, c: 9}
29: {r: 1, c: 8}
30: {r: 1, c: 7}
31: {r: 1, c: 6}
32: {r: 1, c: 5}
33: {r: 1, c: 4}
34: {r: 2, c: 4}
35: {r: 2, c: 5}
36: {r: 2, c: 6}
37: {r: 2, c: 7}
38: {r: 2, c: 8}
39: {r: 3, c: 8}

      */

    const coordMap = new Map();

    // get all neighbors
    // for (let r in grid) {
    //     for (let c in grid[r]) {
    //         const neighborMap = {};
    //         const key = `${r}_${c}`;
    //         for (let i = 0; i < 4; i++) {
    //             const nKey = `${this.rowDirections[i]}_${this.colDirections[i]}`;
    //             neighborMap.set(nKey, 0);
    //         }

    //         coordMap.set(key, neighborMap);
    //     }
    // }

    let prevCoord = null;

    const _setMap = (coord, pCoord, cMap) => {
      const direction = this.getDirectionFromCoord(coord, pCoord);
      if (!direction) return;
      const key = `${coord.r}_${coord.c}`;
      if (!cMap.has(key)) {
        cMap.set(key, {});
      }
      const obj = cMap.get(key);
      obj[direction] = 1;
      cMap.set(key, obj);
    };

    for (let i in path) {
      const coord = path[i];
      _setMap(coord, prevCoord, coordMap);
      _setMap(prevCoord, coord, coordMap);
      prevCoord = coord;
    }

    return coordMap;
  }

  generate(mazeSize) {
    const maze = this.createMaze(mazeSize);
    const skipped = {};
    this.dfs(0, 0, maze, skipped);
    this.setState({
      grid: maze,
    });
  }

  dfs(row, col, grid, skipped) {
    if (row < 0 || row >= grid.length || col < 0 || col >= grid[row].length)
      return -1; //dead end

    const skipKey = `${row}+${col}`;
    if (grid[row][col] === 0 || skipped[skipKey]) return 0; //already visited
    // console.log("stopIndex", stopIndex);

    const skip = this.getRandomNumber(2);

    // if right then down
    // if down then left or right
    if (!skip) {
      grid[row][col] = 0;
    } else {
      skipped[skipKey] = 1;
    }

    this.dfs(row, col + 1, grid, skipped);
    this.dfs(row, col - 1, grid, skipped);
    this.dfs(row + 1, col, grid, skipped);
    this.dfs(row - 1, col, grid, skipped);
  }

  randomWalk(grid) {
    const startTopCol = this.getRandomNumber(grid.length);
    const bottomEndCol = this.getRandomNumber(grid.length);
    let row = 0;
    let col = startTopCol;
    let prevDirection = 1;

    console.log(`start: row ${row} col ${startTopCol}`);
    console.log(`end: row ${bottomEndCol} col ${grid.length - 1}`);

    while (!(col === grid.length - 1 && row === bottomEndCol)) {
      if (row < 0) {
        row = 0;
      }

      if (row > grid.length - 1) {
        row = grid.length - 1;
      }

      if (col < 0) {
        col = 0;
      }

      if (col > grid.length - 1) {
        col = grid.length - 1;
      }
      grid[row][col] = 0;

      const dir = this.getDirection(prevDirection);
      prevDirection = dir;

      switch (dir) {
        case 1: // down
          row++;
          break;
        case 2: // right
          col++;
          break;
        case 3: // up
          row--;
          break;
        case 4: // left
          col--;
          break;
        default:
      }
    }
    this.setState({
      grid,
    });
  }

  getRandomNumber(limit) {
    return Math.floor(Math.random() * limit);
  }

  getDirection(prevDirection) {
    const directions = [1, 2, 3, 4];
    let dir = prevDirection;
    // while (prevDirection === dir) {
    const index = this.getRandomNumber(4);
    dir = directions[index];
    // }

    // if we went down and are going up
    if (prevDirection === 1 && dir === 3) {
      dir = 1;
    } else if (prevDirection === 2 && dir === 4) {
      dir = 2;
    } else if (prevDirection === 3 && dir === 1) {
      dir = 3;
    } else if (prevDirection === 4 && dir === 2) {
      dir = 4;
    }

    return dir;
  }

  componentDidMount() {
    document.body.style.height = "90%";
    document.getElementById("root").style.height = "100%";
    const size = this.getRandomNumber(70);
    // this.generate(size);
    // const grid = this.createMaze(size);
    // this.randomWalk(grid);
    this.randomDfs(size);
  }

  render() {
    const { grid, path } = this.state;
    if (!grid) return null;
    const coordMap = this.constructPathRepresentation(grid, path);
    const colSize = (100 / grid.length).toFixed(2);
    console.log(coordMap);
    return (
      <div className="maze-container">
        {grid.map((r, rowIndex) => {
          return (
            <div className="maze-row" key={"row" + rowIndex}>
              {r.map((c, colIndex) => {
                const width = `${colSize}%`;
                const height = width;
                const k = `${rowIndex}_${colIndex}`;
                const border = coordMap.get(k) || {
                  right: 0,
                  left: 0,
                  up: 0,
                  down: 0,
                };

                return (
                  <div
                    id={k}
                    key={k}
                    className={`maze-col-${c}`}
                    style={{
                      width,
                      //   height,
                      borderRight: border.right === 1 ? "0px" : "",
                      borderLeft: border.left === 1 ? "0px" : "",
                      borderTop: border.up === 1 ? "0px" : "",
                      borderBottom: border.down === 1 ? "0px" : "",
                    }}
                  >
                    &nbsp;
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }
}
