import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";

export default function QuoteData(props) {
  if (!props.instrument) return null;
  /*
    c
    Current price

    d
    Change

    dp
    Percent change

    h
    High price of the day

    l
    Low price of the day

    o
    Open price of the day

    pc
    Previous close price
    */
  let isNegativeChange = false;

  if (parseFloat(props.instrument.quoteData.d) < 0) {
    isNegativeChange = true;
  }

  const percentChange = props.instrument.quoteData.dp.toFixed(2);
  const price = props.instrument.quoteData.c.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const change = props.instrument.quoteData.d.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const adjustedPercentage =
    props.instrument.metrics.metric["52WeekHigh"] -
    props.instrument.metrics.metric["52WeekLow"];
  let fiftyTwoWeekPercentage =
    props.instrument.quoteData.c /
    props.instrument.metrics.metric["52WeekHigh"];
  fiftyTwoWeekPercentage = fiftyTwoWeekPercentage * 100;
  fiftyTwoWeekPercentage = fiftyTwoWeekPercentage - adjustedPercentage;

  return (
    <div className="instrument-quote-data">
      <br />
      <div className="f-label">Price</div>
      <h1 className="bold">
        {price}
        <small
          style={{ fontSize: ".8em" }}
          className={!isNegativeChange ? "text-success" : "text-danger"}
        >
          {isNegativeChange && <BsFillCaretDownFill style={{ color: "Red" }} />}
          {!isNegativeChange && (
            <BsFillCaretUpFill style={{ color: "Green" }} />
          )}
          {` ${isNegativeChange ? "" : "+"}${change} (${percentChange}%)`}
        </small>
      </h1>
      <div className="row">
        <div className="col-6 col-sm-4">
          <div className="f-label">Symbol</div>
          <h5 className="f-value">{props.instrument.metrics.symbol}</h5>
        </div>
        <div className="col-6 col-sm-4">
          <div className="f-label">Previous Close</div>
          <p className="f-value">
            {props.instrument.quoteData.pc.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </p>
        </div>
        <div className="col-12 col-sm-4">
          <div className="f-label">Day Range</div>
          <p className="f-value">
            {props.instrument.quoteData.l.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{" "}
            -
            {props.instrument.quoteData.h.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </p>
        </div>
      </div>
      <div className="high-low">
        <h5 className="f-label">52 Week Range</h5>
        <div className="progress-label f-value">
          {props.instrument.metrics.metric["52WeekLow"].toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          )}
        </div>
        <div className="progress" style={{ height: "5px" }}>
          <div
            className="progress-bar bg-info"
            role="progressbar"
            style={{ width: String(fiftyTwoWeekPercentage) + "%" }}
            aria-valuenow={fiftyTwoWeekPercentage}
            aria-valuemin={0}
            aria-valuemax={100}
          ></div>
        </div>
        {/* <div className="progress-label w-100">
            <div
              style={{
                width: String(fiftyTwoWeekPercentage) + "%",
                display: "inline-block",
              }}
            >
              &nbsp;
            </div>
            <span className="f-value">{price}</span>
          </div> */}

        <div className="progress-label f-value text-end w-100">
          {props.instrument.metrics.metric["52WeekHigh"].toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          )}
        </div>
      </div>
    </div>
  );
}
